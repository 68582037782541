import React, { useCallback, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect, useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { ReactComponent as LogoSvg } from '../../5th_Whel_Lab_Logo.svg';

import app from '../../firebase-config';
import { AuthContext } from '../../Auth.js';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '../../components/Alert';

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    width: '90%',
    maxWidth: '350px',
    margin: '40px auto',
  },
  avatar: {
    margin: '0 auto',
    width: '100%',
    height: theme.spacing(18),
  },
  root: {
    padding: '20px',
    margin: 'auto',
    height: '100%',
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  button: {
    width: '100%',
  },
  input: {
    marginBottom: '30px',
  },
  loginFooter: {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  loginFooterButton: {
    textTransform: 'capitalize',
  },
  avatarIcon: {
    fontSize: '3em',
  },
}));

const Login = () => {
  const history = useHistory();
  const classes = useStyles();

  const [ isErrorSnackbarOpen, setErrorSnackbar ] = useState(false);

  const [
    isSignInWithEmailAndPassword,
    setSignInWithEmailAndPassword,
  ] = useState(false);

  const {
    currentUser,
    hydratedUser,
    isHydratingUser,
  } = useContext(AuthContext);

  const handleLogin = useCallback(
    async event => {
      event.preventDefault();

      const { email, password } = event.target.elements;

      try {
        setSignInWithEmailAndPassword(true);

        await app
          .auth()
          .signInWithEmailAndPassword(email.value, password.value);

      } catch (error) {
        setErrorSnackbar(true);
      } finally {
        setSignInWithEmailAndPassword(false);
      }
    }, []);

  const closeErrorSnackbar = () => {
    setErrorSnackbar(false);
  }

  const redirectTo = (route) => {
    history.push(route);
  }

  if (currentUser && hydratedUser) {
    return <Redirect to="/" />;
  }

  return (
    <Paper classes={{ root: classes.paperRoot }}>
      <div className={classes.root}>
        <div className={classes.avatar}>
          <LogoSvg />
        </div>
        <h2>Sign in</h2>
        <form onSubmit={handleLogin}>
          <TextField
            fullWidth
            name="email"
            type="email"
            label="Email"
            className={classes.input}
            disabled={isHydratingUser}
          />
          <TextField
            fullWidth
            name="password"
            type="password"
            label="Password"
            className={classes.input}
            disabled={isHydratingUser}
          />
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            type="submit"
            disabled={isHydratingUser || isSignInWithEmailAndPassword}
          >
            {(isHydratingUser || isSignInWithEmailAndPassword) ? (
              <CircularProgress size={22} />
            ) : (
              <span>Sign in</span>
            )}
          </Button>
        </form>
        <div className={classes.loginFooter}>
          <Button
            onClick={() => redirectTo('/forgot-password')}
            classes={{ textPrimary: classes.loginFooterButton }}
            color="primary"
          >
            Forgot password?
          </Button>
          <Button
            onClick={() => redirectTo('/sign-up')}
            classes={{ textPrimary: classes.loginFooterButton }}
            color="primary"
          >
            Sign up now
          </Button>
        </div>
      </div>
      <Snackbar
        open={isErrorSnackbarOpen}
        autoHideDuration={50000}
        onClose={closeErrorSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error">Invalid email or password</Alert>
      </Snackbar>
    </Paper>
  );
};

export default Login;
