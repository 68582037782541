import Validator from 'validatorjs';
import React, { useCallback, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect, useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import LocalShipping from '@material-ui/icons/LocalShipping';

import app from '../../firebase-config';
import { AuthContext } from '../../Auth.js';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '../../components/Alert';

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    width: '90%',
    maxWidth: '350px',
    margin: '40px auto',
  },
  avatar: {
    margin: '0 auto',
    width: theme.spacing(14),
    height: theme.spacing(14),
  },
  root: {
    padding: '20px',
    margin: 'auto',
    height: '100%',
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  button: {
    width: '100%',
  },
  input: {
    marginBottom: '30px',
  },
  loginFooter: {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  loginFooterButton: {
    textTransform: 'capitalize',
  },
  avatarIcon: {
    fontSize: '3em',
  },
}));

const forgotPasswordValidationRules = {
  email: 'required|email',
};

const ForgotPassword = () => {
  const classes = useStyles();

  const [
    hasLoadingAnimation,
    setHasLoadingAnimation,
  ] = useState(false);

  const [ snackbar, setSnackbar ] = useState({ isOpen: false, content: null });

  const {
    currentUser,
    hydratedUser,
    isHydratingUser,
  } = useContext(AuthContext);

  const history = useHistory();

  const redirectTo = useCallback((route) => {
    history.push(route);
  }, [history]);

  const handlePasswordReset = useCallback(
    async event => {
      event.preventDefault();

      const { email } = event.target.elements;

      const formValidator = new Validator({ email: email.value }, forgotPasswordValidationRules);

      if (formValidator.fails()) {
        setSnackbar({
          isOpen: true,
          content: (<Alert severity="error">Enter valid email address</Alert>),
        });

        return;
      }

      try {
        setHasLoadingAnimation(true);

        await app.auth().sendPasswordResetEmail(email.value);

        redirectTo('/login');
      } catch (error) {
        setSnackbar({
          isOpen: true,
          content: (<Alert severity="error">Error sending password reset email.</Alert>),
        });
      } finally {
        setHasLoadingAnimation(false);
      }
    }, [redirectTo]
  );

  if (currentUser && hydratedUser) {
    return <Redirect to="/" />;
  }

  const closeSnackbar = () => {
    setSnackbar({ isOpen: false, content: null });
  }

  return (
    <Paper classes={{ root: classes.paperRoot }}>
      <div className={classes.root}>
        <Avatar className={classes.avatar}>
          <LocalShipping className={classes.avatarIcon} />
        </Avatar>
        <h2>Forgot Password?</h2>
        <form onSubmit={handlePasswordReset}>
          <TextField
            fullWidth
            name="email"
            type="email"
            label="Email"
            className={classes.input}
            disabled={isHydratingUser}
          />
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            type="submit"
            disabled={isHydratingUser || hasLoadingAnimation}
          >
            {(isHydratingUser || hasLoadingAnimation) ? (
              <CircularProgress size={22} />
            ) : (
              <span>Reset Password</span>
            )}
          </Button>
        </form>
        <div className={classes.loginFooter}>
          <Button
            onClick={() => redirectTo('/login')}
            classes={{ textPrimary: classes.loginFooterButton }}
            color="primary"
          >
            Sign in
          </Button>
          <Button
            onClick={() => redirectTo('/sign-up')}
            classes={{ textPrimary: classes.loginFooterButton }}
            color="primary"
          >
            Sign up now
          </Button>
        </div>
      </div>
      <Snackbar
        open={snackbar.isOpen}
        autoHideDuration={50000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {snackbar.content}
      </Snackbar>
    </Paper>
  );
};

export default ForgotPassword;
