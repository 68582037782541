import _ from 'lodash';
import { isCarrierLeaseOrCompanyType } from '../../utils/random';
import { ROLES } from '../../users';

const CARRIER_STATUSES = ROLES.carrier.statuses;

export const getCheckedTypes = (types) => {
  const checked = [];
  _.each(types, (value, key) => {
    if (value === true) {
      checked.push(key);
    }
  });

  return checked;
}

export const filterDrivers = (term, types) => (driver) => {
  return _.includes(types, _.get(driver, 'operator_user_metadata.carrier_type'))
    && (
      _.startsWith(_.toLower(driver.display_name), _.toLower(term))
      || _.startsWith(_.toLower(driver.active_vehicle.lpn), _.toLower(term))
      || _.startsWith(_.toString(_.toLower(driver.active_vehicle.number)), _.toLower(term))
    );
}

export const filterUsers = (term) => (user) => {
  return _.startsWith(_.toLower(user.display_name), _.toLower(term));
}

export const getDriverVehicle = (driver) => {
  const type = _.get(driver, 'operator_user_metadata.carrier_type');

  let vehicle;
  if (isCarrierLeaseOrCompanyType(type)) {
    vehicle = _.get(driver, 'operator_user_metadata.carrier_vehicle');
  } else {
    vehicle = _.get(driver, 'carrier_vehicle');
  }

  return {
    id: _.get(vehicle, 'id', '-'),
    make: _.get(vehicle, 'make', '-'),
    number: _.get(vehicle, 'number', '-'),
    trailerNumber: _.get(vehicle, 'trailer_number', '-'),
    lpn: _.get(vehicle, 'lpn', '-'),
  };
};

export const getCarrierStatus = (carrier) => {
  const status = _.get(carrier, 'carrier_status');

  if (status) {
    return _.get(CARRIER_STATUSES, [status, 'displayName'], CARRIER_STATUSES.default.displayName);
  }

  return CARRIER_STATUSES['no-status'].displayName;
};

export const mapToActiveVehicle = driver => ({ ...driver, active_vehicle: getDriverVehicle(driver) });

export const withDefaultStatus = driver => ({ ...driver, displayStatus: getCarrierStatus(driver) });

function descendingComparator(a, b, orderBy) {
  if (_.get(b, orderBy) < _.get(a, orderBy)) {
    return -1;
  }

  if (_.get(b, orderBy) > _.get(a, orderBy)) {
    return 1;
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

export const sortAndPaginate = (order, orderBy, page, rowsPerPage, data) => {
  return stableSort(data, getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export const getOperatorIdFromHydratedUser = (hydratedUser) => {
  if (_.get(hydratedUser, 'role') === ROLES.dispatcher.key) {
    return _.get(hydratedUser, 'operator.id');
  }

  return _.get(hydratedUser, 'id');
}
