export const ROLES = {
  carrier: {
    key: 'carrier',
    displayName: 'Driver',
    types: {
      'carrier-company': {
        key: 'carrier-company',
        displayName: 'Company Driver',
      },
      'carrier-lease': {
        key: 'carrier-lease',
        displayName: 'Lease Driver',
      },
      'carrier-owner-operator': {
        key: 'carrier-owner-operator',
        displayName: 'Owner Operator',
      },
    },
    statuses: {
      'no-status': {
        key: 'no-status',
        displayName: 'No status',
      },
      'ready': {
        key: 'ready',
        displayName: 'Ready',
      },
      'transit': {
        key: 'transit',
        displayName: 'In transit',
      },
      'sleeper': {
        key: 'sleeper',
        displayName: 'Sleeper',
      },
      'default': {
        key: 'no-status',
        displayName: 'No status',
      },
    },
  },
  operator: {
    key: 'operator',
    displayName: 'Operator',
  },
  dispatcher: {
    key: 'dispatcher',
    displayName: 'Dispatcher',
  },
};

async function handler(role) {
  switch(role) {
    case ROLES.carrier.key:
      const { default: carrierUser } = await import('./Carrier');
      return carrierUser;
    case ROLES.operator.key:
      const { default: operatorUser } = await import('./Operator');
      return operatorUser;
    case ROLES.dispatcher.key:
      const { default: dispatcherUser } = await import('./Dispatcher');
      return dispatcherUser;
    default:
      return Promise.reject(new Error('Invalid type'));
  }
}

export default handler;
