import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import AppHeader from './components/AppHeader';
import RoutesSwitch from './routes/RoutesSwitch';
import AppNav from './components/AppNav';
import { AuthContext } from './Auth';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
});

function App(props) {
  const classes = useStyles();
  const { currentUser, hydratedUser } = useContext(AuthContext);

  // TODO this is bull shit man, whole app re-renders when you toggle your stupid Nav!
  const [open, setOpen] = React.useState(localStorage.getItem('isNavOpen') === '1');
  const [pageName, setPageName] = React.useState('');

  const handleDrawerOpen = () => {
    localStorage.setItem('isNavOpen', '1');

    setOpen(true);
  };

  const handleDrawerClose = () => {
    localStorage.setItem('isNavOpen', '0');

    setOpen(false);
  };

  // TODO Temp shit, remove whenever
  useEffect(() => {
    if (currentUser) {
      currentUser.getIdTokenResult().then((idTokenResult) => {
        // console.log(idTokenResult.token);
        // console.log(hydratedUser);
      });
    }
  }, [currentUser, hydratedUser]);

  return (
    <>
      <CssBaseline />
      <div className={classes.root}>
        {(currentUser && hydratedUser) ? (
          <>
            <AppHeader open={open} pageName={pageName} onDrawerOpen={handleDrawerOpen} />
            <AppNav open={open} onDrawerClose={handleDrawerClose} />
          </>
        ) : null}
        <RoutesSwitch {...props} setPageName={setPageName} />
      </div>
    </>
  );
}

export default App;
