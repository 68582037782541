import _ from 'lodash';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { green, blue, orange } from '@material-ui/core/colors';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ReadyIcon from '@material-ui/icons/FiberManualRecord';
import SleeperIcon from '@material-ui/icons/LocalHotel';
import InTransitIcon from '@material-ui/icons/LocalShipping';
import NoStatusIcon from '@material-ui/icons/RemoveCircleOutline';

import { AuthContext } from '../../Auth';
import App from '../../firebase-config';
import { ROLES } from '../../users';
import { patchUser } from '../../services/users';
import { getCarrierStatus } from '../Operator/utils';

const NAV_DRAWER_WIDTH = 240;

const CARRIER_STATUSES = ROLES.carrier.statuses;

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.between('md', 'xl')]: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  appBarShift: {
    [theme.breakpoints.between('md', 'xl')]: {
      marginLeft: NAV_DRAWER_WIDTH,
      width: `calc(100% - ${NAV_DRAWER_WIDTH}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  profile: {
    position: 'absolute',
    right: '11px',
  },
  link: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  listItemIconRoot: {
    minWidth: '34px',
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  hide: {
    display: 'none',
  },
  nestedMenuItem: {
    paddingLeft: theme.spacing(1),
  },
  statusButton: {
    marginRight: theme.spacing(2),
    minWidth: '100px',
    padding: '4px 6px',
    fontSize: '0.7225rem',
  },
  green: {
    color: '#fff',
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[300],
    },
  },
  blue: {
    color: '#fff',
    backgroundColor: blue[400],
    '&:hover': {
      backgroundColor: blue[300],
    },
  },
  orange: {
    color: '#fff',
    backgroundColor: orange[400],
    '&:hover': {
      backgroundColor: orange[300],
    },
  },
}));

function AppHeader(props) {
  const classes = useStyles();
  const { currentUser, hydratedUser } = useContext(AuthContext);
  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const [ statusMenuAnchorEl, setStatusMenuAnchorEl ] = React.useState(null);
  const [ status, setStatus ] = React.useState(getCarrierStatus(hydratedUser));
  const [ savingStatus, setSavingStatus ] = React.useState(false);

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  const handleStatusMenuClick = (event) => {
    setStatusMenuAnchorEl(event.currentTarget);
  };

  const handleStatusClose = () => {
    setStatusMenuAnchorEl(null);
  };

  const logout = () => {
    App.auth().signOut();
  };

  const handleStatusChange = (newStatus) => () => {
    const { key, displayName } = _.get(CARRIER_STATUSES, newStatus, 'default');
    handleStatusClose();

    if (status === displayName) {
      return;
    }

    setSavingStatus(true);
    patchUser(currentUser, { carrier_status: key })
      .then(() => {
        setStatus(displayName);
      }).finally(() => {
        setSavingStatus(false);
      });
  };

  const getStatusColorClass = () => {
    switch(status) {
      case CARRIER_STATUSES.ready.displayName:
        return classes.green;
      case CARRIER_STATUSES.transit.displayName:
        return classes.orange;
      case CARRIER_STATUSES.sleeper.displayName:
        return classes.blue;
      default:
        return '';
    }
  };

  const { open, pageName, onDrawerOpen } = props;

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={onDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: open,
          })}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap>
          {pageName}
        </Typography>
        <div className={classes.profile}>
          {hydratedUser.role === ROLES.carrier.key ? (
            <>
              <Button
                size="small"
                variant="contained"
                className={`${classes.statusButton} ${getStatusColorClass()}`}
                endIcon={!savingStatus ? <ExpandMore /> : <CircularProgress size={15} />}
                onClick={handleStatusMenuClick}
                aria-controls="status-menu"
              >
                {status}
              </Button>
              <Menu
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                getContentAnchorEl={null}
                classes={{ list: classes.menuList }}
                anchorEl={statusMenuAnchorEl}
                open={Boolean(statusMenuAnchorEl)}
                onClose={handleStatusClose}
                id="status-menu"
              >
                <MenuItem
                  className={classes.nestedMenuItem}
                  onClick={handleStatusChange(CARRIER_STATUSES.ready.key)}
                >
                  <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                    <ReadyIcon />
                  </ListItemIcon>
                  <ListItemText primary={CARRIER_STATUSES.ready.displayName} />
                </MenuItem>
                <Divider />
                <MenuItem
                  className={classes.nestedMenuItem}
                  onClick={handleStatusChange(CARRIER_STATUSES.transit.key)}
                >
                  <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                    <InTransitIcon />
                  </ListItemIcon>
                  <ListItemText primary={CARRIER_STATUSES.transit.displayName} />
                </MenuItem>
                <Divider />
                <MenuItem
                  className={classes.nestedMenuItem}
                  onClick={handleStatusChange(CARRIER_STATUSES.sleeper.key)}
                >
                  <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                    <SleeperIcon />
                  </ListItemIcon>
                  <ListItemText primary={CARRIER_STATUSES.sleeper.displayName} />
                </MenuItem>
                <Divider />
                <MenuItem
                  className={classes.nestedMenuItem}
                  onClick={handleStatusChange(CARRIER_STATUSES['no-status'].key)}
                >
                  <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                    <NoStatusIcon />
                  </ListItemIcon>
                  <ListItemText primary={CARRIER_STATUSES['no-status'].displayName} />
                </MenuItem>
              </Menu>
            </>
          ) : null}

          <IconButton
            onClick={handleProfileClick}
            aria-controls="profile-menu"
            aria-haspopup="true"
            color="inherit"
            aria-label="open profile menu"
            edge="start"
          >
            <PersonIcon />
          </IconButton>
          <Menu
            classes={{ list: classes.menuList }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleProfileClose}
          >

            {_.get(hydratedUser, 'operator.id') ? (
              <Link to="/account/company" className={classes.link}>
                <MenuItem>
                  <ListItemText
                    primary={_.get(hydratedUser, 'operator.operator_name', '-')}
                    secondary={`DOT: ${_.get(hydratedUser, 'operator.operator_dot_number', '-')}`}
                  />
                </MenuItem>
              </Link>
            ) : null}
            {_.get(hydratedUser, 'operator.id') ? (<Divider />) : null}

            <Link to="/account" className={classes.link}>
              <MenuItem onClick={handleProfileClose}>
                <ListItemText
                  primary={_.get(currentUser, 'displayName', '-')}
                  secondary="Account"
                />
              </MenuItem>
              <Divider />
            </Link>

            <Link to="/account/profile" className={classes.link}>
              <MenuItem onClick={handleProfileClose}>
                <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                  <PersonIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Edit Profile" />
              </MenuItem>
              <Divider />
            </Link>
            {_.map(_.get(hydratedUser, 'components.headerNavigation', []), item => (
              <Link to={item.route} className={classes.link} key={item.name}>
                <MenuItem onClick={handleProfileClose}>
                  <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </MenuItem>
                <Divider />
              </Link>
            ))}
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}

AppHeader.propTypes = {
  open: PropTypes.bool.isRequired,
  pageName: PropTypes.string.isRequired,
  onDrawerOpen: PropTypes.func.isRequired,
};

export default AppHeader;
