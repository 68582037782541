export async function commonHeadersWithToken(user, rest = {}) {
  const token = await user.getIdToken();

  return {
    headers: {
      common: {
        'Authorization': `Bearer ${token}`,
      },
       ...rest,
    }
  };
}
