import axios from 'axios';
import CONFIG from 'CONFIG';
import { commonHeadersWithToken } from '../utils/http';

const HOST = `${CONFIG.USERS_API_URL}/user`;

export async function hydrateUser(user) {
  const url = `${CONFIG.USERS_API_URL}/user-hydrate`;
  const config = await commonHeadersWithToken(user);

  return axios.get(url, config);
}

export async function getUser(user) {
  const config = await commonHeadersWithToken(user);

  return axios.get(`${HOST}/${user.uid}`, config);
}

export async function patchUser(user, body) {
  const config = await commonHeadersWithToken(user);

  return axios.patch(`${HOST}/${user.uid}`, body, config);
}

export function createUser(user) {
  return async function(body) {
    const config = await commonHeadersWithToken(user);

    return axios.post(HOST, body, config);
  }
}

export async function deleteUserAccount(user) {
  const config = await commonHeadersWithToken(user);

  return axios.delete(`${CONFIG.USERS_API_URL}/delete-user-account`, config);
}

export async function deleteUserVehicle(user) {
  const config = await commonHeadersWithToken(user);

  return axios.delete(`${CONFIG.USERS_API_URL}/delete-user-vehicle`, config);
}

export async function getPreSignedUploadUrl(user, body) {
  const url = `${CONFIG.USERS_API_URL}/user-asset-pre-signed-upload-url`;
  const config = await commonHeadersWithToken(user);

  return axios.post(url, body, config);
}

export async function getPreSignedUrl(user, body) {
  const url = `${CONFIG.USERS_API_URL}/user-asset-pre-signed-url`;
  const config = await commonHeadersWithToken(user);

  return axios.post(url, body, config);
}

export async function getOperatorUsers(user) {
  const config = await commonHeadersWithToken(user);
  const url = `${CONFIG.USERS_API_URL}/operator-users`;

  return axios.get(url, config);
}

export async function getAllUsersInternal(user) {
  const config = await commonHeadersWithToken(user);
  const url = `${CONFIG.USERS_API_URL}/internal/users/activity`;

  return axios.get(url, config);
}
