import _ from 'lodash';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { AuthContext } from '../../Auth';

const NAV_DRAWER_WIDTH = 240;

const styles = theme => ({
  drawer: {
    width: NAV_DRAWER_WIDTH,
    whiteSpace: 'nowrap',
    flexShrink: 0,
  },
  drawerOpen: {
    [theme.breakpoints.down('md')]: {
      width: NAV_DRAWER_WIDTH,
    },
    [theme.breakpoints.between('md', 'xl')]: {
      width: NAV_DRAWER_WIDTH,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  drawerClose: {
    [theme.breakpoints.down('md')]: {
      width: NAV_DRAWER_WIDTH,
    },
    [theme.breakpoints.between('md', 'xl')]: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(8) + 1,
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  link: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
  },
});

function AppNav(props) {
  const { open, classes, onDrawerClose } = props;

  const theme = useTheme();
  const location = useLocation();
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'xl'));
  const { hydratedUser } = useContext(AuthContext);

  const isActiveNavItem = (item) => {
    const route =  _.split(item.route, '/');
    const currentRoute =  _.split(location.pathname, '/');

    return _.get(route, '[1]') === _.get(currentRoute, '[1]');
  }

  const maybeCloseNav = () => {
    if (open && !isLargeScreen) {
      onDrawerClose();
    }
  }

  return (
    <Drawer
      open={open}
      variant={isLargeScreen ? 'permanent' : undefined}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={onDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {_.map(_.get(hydratedUser, 'components.mainNavigation', []), item => (
          <Link
            key={item.name}
            to={item.route}
            className={classes.link}
          >
            <ListItem
              button
              selected={isActiveNavItem(item)}
              onClick={maybeCloseNav}
            >
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          </Link>
        ))}
      </List>
    </Drawer>
  );
}

AppNav.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  onDrawerClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(AppNav);
