import _ from 'lodash';
import Validator from 'validatorjs';
import React, { useCallback, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect, useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import HeadsetMic from '@material-ui/icons/HeadsetMic';
import LocalShipping from '@material-ui/icons/LocalShipping';
import ListItemText from '@material-ui/core/ListItemText';

import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { AlertTitle } from '@material-ui/lab';

import app from '../../firebase-config';
import { AuthContext } from '../../Auth.js';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '../../components/Alert';
import { UserFactory } from '../../users/User';
import { ROLES } from '../../users';
import { ReactComponent as LogoSvg } from '../../5th_Whel_Lab_Logo.svg';

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    width: '90%',
    maxWidth: '350px',
    margin: '40px auto',
  },
  avatar: {
    margin: '0 auto',
    width: '100%',
    height: theme.spacing(18),
  },
  root: {
    padding: '20px',
    margin: 'auto',
    height: '100%',
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  button: {
    width: '100%',
  },
  input: {
    marginBottom: '30px',
  },
  loginFooter: {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  loginFooterButton: {
    textTransform: 'capitalize',
  },
  avatarIcon: {
    fontSize: '3em',
  },
}));

const signUpCarrierValidationRules = {
  type: 'required|string|in:carrier,operator',
  name: 'required|string',
  email: 'required|email',
  password: 'required|string',
};

const signUpOperatorValidationRules = {
  type: 'required|string|in:carrier,operator',
  name: 'required|string',
  company_name: 'required|string',
  dot_number: 'required|string',
  email: 'required|email',
  password: 'required|string',
};

const SignUp = () => {
  const classes = useStyles();

  const [ type, setType ] = useState(null);
  const [ hasLoadingAnimation, setHasLoadingAnimation ] = useState(false);
  const [ snackbar, setSnackbar ] = useState({ isOpen: false, content: null });

  const {
    currentUser,
    hydratedUser,
    isHydratingUser,
  } = useContext(AuthContext);

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const history = useHistory();

  const handleCreateUser = useCallback(
    async event => {
      event.preventDefault();

      const getSignUpValidator = form => {
        switch (type) {
          case ROLES.carrier.key:
            return new Validator({
              type,
              name: form.name.value,
              email: form.email.value,
              password: form.password.value,
            }, signUpCarrierValidationRules);
          case ROLES.operator.key:
            return new Validator({
              type,
              company_name: form.company_name.value,
              dot_number: form.dot_number.value,
              name: form.name.value,
              email: form.email.value,
              password: form.password.value,
            }, signUpOperatorValidationRules);
          default:
            return null;
        }
      };

      const signUpValidator = getSignUpValidator(event.target.elements);
      if (!signUpValidator || signUpValidator.fails()) {
        setSnackbar({
          isOpen: true,
          content: (
            <Alert severity="error">
              <AlertTitle>Sign up Error</AlertTitle>
              All sign up fields are required
            </Alert>
          ),
        });

        return;
      }

      const {
        name,
        email,
        password,
      } = event.target.elements;

      const userBody = {
        display_name: name.value,
        email: email.value,
      };

      let userRoleBody = {};
      if (type === ROLES.operator.key) {
        userRoleBody = {
          operator_name: event.target.elements.company_name.value,
          operator_dot_number: event.target.elements.dot_number.value,
        };
      }

      try {
        setHasLoadingAnimation(true);

        await app.auth().createUserWithEmailAndPassword(email.value, password.value).then(async (response) => {
          const currentUser = _.get(response, 'user', {});
          const user = new UserFactory(type, currentUser);

          try {
            return user.create({ ...userRoleBody, ...userBody }).then(async () => {
              await currentUser.updateProfile({ displayName: name.value });
              await currentUser.sendEmailVerification();
            }).catch(async function () {
              // Promise rejection catch clause
              await currentUser.delete();
              return Promise.reject(new Error('Error creating internal user record'));
            });
          } catch (e) {
            // General error catch clause
            await currentUser.delete();
            return Promise.reject(new Error('Error creating internal user record'));
          }
        });
      } catch (error) {
        let errorMessage = 'Error creating new account';
        if (_.get(error, 'code') && _.get(error, 'message')) {
          errorMessage = error.message;
        }

        setSnackbar({
          isOpen: true,
          content: (<Alert severity="error">{errorMessage}</Alert>),
        });
      } finally {
        setHasLoadingAnimation(false);
      }
    }, [type]);

  if (currentUser && hydratedUser) {
    return <Redirect to="/" />;
  }

  const closeSnackbar = () => {
    setSnackbar({ isOpen: false, content: null });
  };

  const redirectTo = (route) => {
    history.push(route);
  };

  return (
    <Paper classes={{ root: classes.paperRoot }}>
      <div className={classes.root}>
        <div className={classes.avatar}>
          <LogoSvg />
        </div>
        <h2>Sign Up</h2>
        <form onSubmit={handleCreateUser}>
          <RadioGroup
            name="type"
            value={type}
            onChange={handleTypeChange}
            disabled={isHydratingUser || hasLoadingAnimation}
          >
            <List>
              <ListItem disableGutters>
                <ListItemIcon>
                  <LocalShipping />
                </ListItemIcon>
                <ListItemText
                  primary="Driver"
                  secondary="Independent or company driver."
                />
                <Radio value="carrier" />
              </ListItem>
              <Divider />
              <ListItem disableGutters>
                <ListItemIcon>
                  <HeadsetMic />
                </ListItemIcon>
                <ListItemText
                  primary="Dispatcher"
                  secondary="Dispatcher or Owner Operator."
                />
                <Radio value="operator" />
              </ListItem>
            </List>
          </RadioGroup>
          <TextField
            fullWidth
            name="name"
            type="text"
            label="Name"
            className={classes.input}
            disabled={isHydratingUser || hasLoadingAnimation || !type}
          />
          {type === ROLES.operator.key ? (
            <>
              <TextField
                fullWidth
                name="company_name"
                type="text"
                label="Company Name"
                className={classes.input}
                disabled={isHydratingUser || hasLoadingAnimation || !type}
              />
              <TextField
                fullWidth
                name="dot_number"
                type="text"
                label="DOT Number"
                className={classes.input}
                disabled={isHydratingUser || hasLoadingAnimation || !type}
              />
            </>
          ) : null}
          <TextField
            fullWidth
            name="email"
            type="email"
            label="Email"
            className={classes.input}
            disabled={isHydratingUser || hasLoadingAnimation || !type}
          />
          <TextField
            fullWidth
            name="password"
            type="password"
            label="Password"
            className={classes.input}
            disabled={isHydratingUser || hasLoadingAnimation || !type}
          />
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            type="submit"
            disabled={isHydratingUser || hasLoadingAnimation || !type}
          >
            {(isHydratingUser || hasLoadingAnimation) ? (
              <CircularProgress size={22} />
            ) : (
              <span>Create Account</span>
            )}
          </Button>
        </form>
        <div className={classes.loginFooter}>
          <Button
            onClick={() => redirectTo('/login')}
            classes={{ textPrimary: classes.loginFooterButton }}
            color="primary"
          >
            Sign in
          </Button>
        </div>
      </div>
      <Snackbar
        open={snackbar.isOpen}
        autoHideDuration={50000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {snackbar.content}
      </Snackbar>
    </Paper>
  );
};

export default SignUp;
