import _ from 'lodash';
import React, { useContext } from 'react';

import AnonymousRoutes from './AnonymousRoutes';
import { AuthContext } from '../Auth';

const RoutesSwitch = (props) => {
  const { hydratedUser } = useContext(AuthContext);

  if (_.get(hydratedUser, 'components.routes')) {
    return hydratedUser.components.routes(props);
  }

  return <AnonymousRoutes />;
};

export default RoutesSwitch;
