import _ from 'lodash';
import PropTypes from 'prop-types';

import { ROLES } from '../../users';

function CarrierRoleName(props) {
  const { role, carrierType } = props;

  return (
    _.get(ROLES, [role, 'types', carrierType, 'displayName'], '-')
  );
}

CarrierRoleName.propTypes = {
  role: PropTypes.string.isRequired,
  carrierType: PropTypes.string,
};

CarrierRoleName.defaultProps = {
  carrierType: null,
};

export default CarrierRoleName;
