import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import SignUp from '../pages/SignUp';

const Routes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/sign-up" component={SignUp} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Redirect to="/login" />
      </Switch>
    </Suspense>
  );
};

export default Routes;
