import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';

import app from './firebase-config';
import EntirePageLoading from './components/EntirePageLoading';
import { hydrateUser } from './services/users';
import Alert from './components/Alert';

import { withRetry } from './utils/promise';
import userHandler from './users';

export const AuthContext = React.createContext({
  currentUser: null,
  hydratedUser: null,
  isHydratingUser: false,
  setCarrierOperator: _.noop,
  setCarrierVehicle: _.noop,
  updateHydratedUser: _.noop,
});

export const AuthProvider = ({ children }) => {
  const [isHydratingUser, setIsHydratingUser] = useState(false);
  const [hydratedUser, setHydratedUser] = useState(null);

  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState(null);

  useEffect(() => {
    app.auth().onAuthStateChanged(async (user) => {
      let currentUser = user;
      let hydratedUser = null;

      if (currentUser) {
        try {
          setIsHydratingUser(true);

          const { data: userData } = await withRetry(hydrateUser, [currentUser]);

          hydratedUser = {
            ...userData,
            components: await userHandler(userData.role),
          };
        } catch (e) {
          /*
           * User hydration is important!!!
           * When hydration fails need to log user out.
           */
          await app.auth().signOut();
          hydratedUser = null;
          currentUser = null;

          openSnackbar();
        }
      }

      setHydratedUser(hydratedUser);
      setCurrentUser(currentUser);

      setIsHydratingUser(false);
      setPending(false);
    });
  }, []);

  /**
   * Carrier hook for updating Operator.
   * Used when carrier switches between operators
   * @param operator
   */
  const setCarrierOperator = (operator) => {
    setHydratedUser((prevState) => ({ ...prevState, operator }));
  }

  /**
   * Carrier hook for updating Vehicle.
   * Used when carrier updates personal vehicle
   * @param vehicle
   */
  const setCarrierVehicle = (vehicle) => {
    setHydratedUser((prevState) => ({ ...prevState, carrier_vehicle: vehicle }));
  }

  const updateHydratedUser = (hydratedUser) => {
    setHydratedUser((prevState) => ({ ...prevState, ...hydratedUser }));
  }

  const openSnackbar = () => {
    setSnackbarContent(<Alert severity="error">Error getting user information.</Alert>);
    setIsSnackbarOpen(true);
  }

  const closeSnackbar = () => {
    setIsSnackbarOpen(false);
  }

  if (pending) {
    return <EntirePageLoading />;
  }

  return (
    <>
      <AuthContext.Provider
        value={{
          currentUser,
          hydratedUser,
          isHydratingUser,
          setCarrierOperator,
          setCarrierVehicle,
          updateHydratedUser,
        }}
      >
        {children}
      </AuthContext.Provider>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={5000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        {snackbarContent}
      </Snackbar>
    </>
  );
};
