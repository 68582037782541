import { ROLES } from './index';
import { createUser } from '../services/users';

export class UserFactory {
  constructor(type, user) {
    switch(type) {
      case ROLES.carrier.key:
        return new CarrierUser(user);
      case ROLES.operator.key:
        return new OperatorUser(user);
      default:
        throw Error('Invalid type');
    }
  }
}

class User {
  constructor(user) {
    this.authUser = user;
  }

  createUser(userBody) {
    const createUserHandler = createUser(this.authUser);

    const body = {
      id: this.authUser.uid,
      ...userBody,
    };

    return createUserHandler(body);
  }
}

class CarrierUser extends User {
  constructor(user) {
    super(user);
    this.authUser = user;
    this.type = ROLES.carrier;
  }

  create(body = {}) {
    return super.createUser({ role: this.type.key, ...body });
  }
}

class OperatorUser extends User {
  constructor(user) {
    super(user);
    this.authUser = user;
    this.type = ROLES.operator;
  }

  create(body = {}) {
    return super.createUser({ role: this.type.key, ...body });
  }
}
