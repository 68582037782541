import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  root: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    marginTop: '-80px',
    marginLeft: '-40px',
  },
};

const EntirePageLoading = (props) => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <CircularProgress size={80} />
    </div>
  );
};

EntirePageLoading.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(EntirePageLoading);
