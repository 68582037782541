import * as firebase from "firebase/app";
import "firebase/auth";

const app = firebase.initializeApp({
  apiKey: "AIzaSyAbuU9YMZI3W3wa8Si4V37IbiUOQYLQuE4",
  authDomain: "trip-forms.firebaseapp.com",
  databaseURL: "https://trip-forms.firebaseio.com",
  projectId: "trip-forms",
  storageBucket: "trip-forms.appspot.com",
  messagingSenderId: "67240764924",
  appId: "1:67240764924:web:e73eaaee42eee16c403e79"
});

export default app;
