import _ from 'lodash';
import dayjs from 'dayjs';
import React from 'react';
import { ROLES } from '../users';
import CarrierRoleName from '../components/UserManagement/RoleName';

export const collectionHasUndefined = trips => !_.filter(trips, function(t) { return !t; }).length;

export const toSafeNumberOrZero = (number) => {
  const maybeNumber = _.toNumber(number);
  return _.isNumber(maybeNumber) && !_.isNaN(maybeNumber) ? maybeNumber : 0;
}

export const calculatePricePerItems = (price, items) => {
  return _.round(toSafeNumberOrZero(price) * toSafeNumberOrZero(items), 2);
}

export const numberFormatter = (number, options = {}) =>
  new Intl.NumberFormat(
    'en-US', {
      ...options
    }
  ).format(number);

export const parseNumberOrDash = number => {
  return number ? numberFormatter(number) : '-';
};

export const parseCurrencyOrDash = number => {
  return number ? `$${numberFormatter(number)}` : '-';
};

export const createDisplayNamePrimary = (date) => {
  return dayjs(date).format('MMM DD, YYYY');
}

// TODO this is Trips specific... move to utils/trips.js
export const createDisplayNameSecondary = (pickup, drop) => {
  if (pickup && drop) {
    return _.join(
      [
        pickup || undefined,
        drop || undefined,
      ],
      ' - '
    );
  }
}

export function roleToDisplayName(user) {
  const role = _.get(user, 'role');
  const carrierType = _.get(user, 'operator_user_metadata.carrier_type');

  if (role === ROLES.carrier.key && carrierType) {
    return (
      <CarrierRoleName
        role={role}
        carrierType={carrierType}
      />
    );
  }

  return _.get(ROLES, `${role}.displayName`, '-');
}

export function isCarrierLeaseOrCompanyType(carrierType) {
  const {
    carrier: {
      types: {
        'carrier-lease': { key: CARRIER_LEASE_TYPE },
        'carrier-company': { key: CARRIER_COMPANY_TYPE }
      }
    }
  } = ROLES;

  return _.includes([CARRIER_LEASE_TYPE, CARRIER_COMPANY_TYPE], carrierType);
}

export function carrierOperatorToCarrierRoleDisplayName(operator) {
  const carrierType = _.get(operator, 'operator_user_metadata.carrier_type');

  return _.get(ROLES, ['carrier', 'types', carrierType, 'displayName'], '-');
}
