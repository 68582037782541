import _ from 'lodash';

export async function withRetry(fn, args, attempts = 4, currentAttempt = 1) {
  if (currentAttempt > attempts) {
    return Promise.reject(new Error(`Failed after ${currentAttempt} attempts`));
  }

  try {
    return await fn(...args);
  } catch (error) {
    if (_.get(error, 'code') === 'auth/app-deleted') {
      return Promise.reject(new Error(error.code));
    }

    await Promise.delay(4000);

    return withRetry(fn, args, attempts, ++currentAttempt);
  }
}
